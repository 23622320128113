import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import React from 'react';
import App from './App';
import {SidebarProvider} from './commons/contexts/SidebarContext';
import * as serviceWorker from './serviceWorker';
import {TimerProvider} from './commons/contexts/TimersContext';

ReactDOM.render(
  <TimerProvider>
    <HelmetProvider>
      <SidebarProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SidebarProvider>
    </HelmetProvider>
  </TimerProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
