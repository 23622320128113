/* eslint-disable @typescript-eslint/no-unused-vars */
import {Dialog, DialogTitle, Divider, DialogContent, Alert} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import React, {createContext, useContext, useState, useEffect, useMemo} from 'react';

import {useAuth} from 'src/commons/contexts/AuthContext';
import FormLogin from '../components/Login/Form';
import {AISCacheService} from '../services/cacheService';

// Créer un contexte pour les deux timers
const TimerContext = createContext<{
  statusConnectionOk: boolean;
  startTimerConnexion: () => void;
  stopTimerConnexion: () => void;
  time2: number;
  startTimerTasks: () => void;
  stopTimerTasks: () => void;
}>({
  statusConnectionOk: true,
  startTimerConnexion: () => {},
  time2: 0,
  startTimerTasks: () => {},
  stopTimerConnexion: () => {},
  stopTimerTasks: () => {},
});

export function useTimers() {
  return useContext(TimerContext);
}

export function TimerProvider({children}) {
  const [statusConnectionOk, setStatusConnectionOk] = useState(true);
  const [time2, setTime2] = useState(0);
  const [idTimerConnexion, setIdTimerConnexion] = useState<NodeJS.Timeout | null>(null);
  const [intervalId2, setIntervalId2] = useState<NodeJS.Timeout | null>(null);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const {check} = useAuth();

  /** ********************************
   *
   * Timer for the check connexion
   *
   ********************************* */

  const startTimerConnexion = () => {
    if (!idTimerConnexion) {
      const id = setInterval(() => {
        checkConnection();
      }, 60000);

      setIdTimerConnexion(id);
    }
  };

  const checkConnection = async () => {
    if (AISCacheService.getUser()) {
      check(checkFailed);
    }
  };

  const checkFailed = () => {
    stopTimerConnexion();
    startConfirmChecked();
  };

  const stopTimerConnexion = () => {
    if (idTimerConnexion) {
      clearInterval(idTimerConnexion);
      startConfirmChecked();
    }
  };

  const startConfirmChecked = () => {
    setTimeout(() => {
      checkConnectionConfirm();
    }, 10000);
  };

  const checkConfirmFailed = () => {
    setOpen(true);
  };

  const checkConfirmSuccess = () => {
    startTimerConnexion();
  };

  const checkConnectionConfirm = async () => {
    check(checkConfirmFailed, checkConfirmSuccess);
  };

  const handleClose = () => {
    setOpen(false);
    // checkConnection()
    window.location.reload();
  };

  /** ********************************
   *
   * Timer for the check connexion
   *
   ********************************* */

  const startTimerTasks = () => {
    if (!intervalId2) {
      const id = setInterval(processingTimerTask, 1000);

      setIntervalId2(id);
    }
  };

  const stopTimerTasks = () => {
    if (intervalId2) {
      clearInterval(intervalId2);
      setIntervalId2(null);
    }
  };

  const processingTimerTask = () => {
    console.log('TIMER 2');
  };

  // Mémorisation de l'objet contextuel
  const contextValue = useMemo(
    () => ({
      statusConnectionOk,
      time2,
      startTimerConnexion,
      startTimerTasks,
      stopTimerConnexion,
      stopTimerTasks,
    }),
    [statusConnectionOk, time2],
  );

  useEffect(() => {
    startTimerConnexion();
    // startTimerTasks();
    return () => {
      stopTimerConnexion();
      stopTimerTasks();
    };
  }, []);

  return (
    <TimerContext.Provider value={contextValue}>
      {children}
      <Dialog
        open={open}
        maxWidth={'lg'}
        onClose={undefined}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          //   sx={{backgroundColor: theme.palette.primary.dark, color: theme.colors.alpha.white[100]}}
          sx={{backgroundColor: 'grey', color: 'white'}}
        >
          {'Expired session'}
        </DialogTitle>
        <Divider />
        <DialogContent sx={{width: 800}}>
          <Alert severity="error" sx={{mb: 2}}>
            Your session has expired due to inactivity, <strong>please log in again.</strong>
          </Alert>
          <FormLogin handleLoginSuccess={handleClose} />
        </DialogContent>
      </Dialog>
    </TimerContext.Provider>
  );
}
